/**
 * Transforms a position in a string defined by index (e.g. 263) into a position
 * defined by row and column (e.g. {row: 10, column: 4}).
 * Why? Ace Editor annotations require row and column positions and JSON parse
 * errors provide position as a character index. This function makes it easier
 * to display JSON parse errors as error annotations.
 * @param {string} source
 * @param {number} index a position of a character in the source
 * @returns {{row: number, column: number}}
 */
export function charIndexToRowAndColumn(source, index) {
  // Find the number of rows by counting the number of '\n' in the partial string.
  const partial = source.slice(0, index);

  // The number of '\n' gives the row index.
  const sum = (a, b) => a + b;
  let row = Array.from(partial)
    .map(char => (char === "\n" ? 1 : 0))
    .reduce(sum);

  // Ace rows are 0-indexed, so this function will also return a 0-indexed row.
  row -= 1;

  // Find the column index by subtracting character position by the number
  // of characters of the string that have already been accounted for.
  const column = index - partial.length;

  return { row, column };
}

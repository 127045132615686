import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import rewind from "@mapbox/geojson-rewind";
import cheapRuler from "cheap-ruler";
import * as turf from "@turf/turf";
import numeral from "numeral";
import geojsonhint from "@mapbox/geojsonhint";

import { defaultMapStyle } from "../../mapStyle/defaultMapStyle";
import { defaultGeojson } from "../../utils/geojson";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYnJ5aWsiLCJhIjoiY2pldXI4cWJsMDJpZjJwczJobnF2a3EyaSJ9.8UZPQ-RgQyLuobMDdzGmyA";

Map.propTypes = {
  mapStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  drawMode: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string
};
Map.defaultProps = {
  width: "100vw",
  height: "100vh"
};

export default function Map(props) {
  const { width, height, mapStyle, drawMode, dispatch } = props;
  const [map, setMap] = useState(null);
  // A reference to the current Draw control is required to remove it from
  // the map when draw mode is toggled off.
  const drawControlRef = useRef(null);

  // Initialization.
  // This effect hook should only run once.
  const mapContainerRef = useRef(null);
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: defaultMapStyle,
      hash: true,
      antialias: true
    });

    // Expose map for debugging (and for hacking)
    window._gsMap = map;

    map.on("load", () => {
      setMap(map);
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        })
      );

      map.addControl(
        new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: "metric"
        })
      );

      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
    });

    // Credit for original measuring code: Andrew Harvey.
    // https://github.com/mapbox/mapbox-gl-draw/issues/801#issuecomment-403360815
    map.on("draw.render", e => {
      const ruler = cheapRuler(map.getCenter().lat, "meters");
      let labelFeatures = [];
      let all = drawControlRef.current.getAll();
      if (all && all.features) {
        all.features.forEach(function(feature) {
          switch (turf.getType(feature)) {
            case "Point":
              // label Points
              if (feature.geometry.coordinates.length > 1) {
                labelFeatures.push(
                  turf.point(feature.geometry.coordinates, {
                    type: "point",
                    label:
                      feature.geometry.coordinates[1].toFixed(6) +
                      ", " +
                      feature.geometry.coordinates[0].toFixed(6)
                  })
                );
              }
              break;
            case "LineString":
              // label Lines
              if (feature.geometry.coordinates.length > 1) {
                // Compute total length of the linestring.
                let length = ruler.lineDistance(feature.geometry.coordinates);
                let lengthLabel = numeral(length).format("0,0.0a") + "m";
                // Compute bearing (between last two points in the linestring).
                const coordCount = feature.geometry.coordinates.length;
                const lastTwoPoints = feature.geometry.coordinates.slice(
                  coordCount - 2
                );
                let bearing = ruler.bearing(...lastTwoPoints);
                let bearingLabel = `\n${bearing.toFixed(3)}°`;

                let label = lengthLabel + " " + bearingLabel;
                let midpoint = ruler.along(
                  feature.geometry.coordinates,
                  length / 2
                );
                labelFeatures.push(
                  turf.point(midpoint, {
                    type: "line",
                    label: label
                  })
                );
              }
              break;
            case "Polygon":
              // label Polygons
              if (
                feature.geometry.coordinates.length > 0 &&
                feature.geometry.coordinates[0].length > 3
              ) {
                let area = ruler.area(feature.geometry.coordinates);
                let label = numeral(area).format("0,0.0a") + "m²";
                labelFeatures.push(
                  turf.centroid(feature, {
                    type: "area",
                    label: label
                  })
                );
              }
              break;
            default:
              console.error(`Unexpected case: ${turf.getType(feature)}`);
          }
        });
      }
      map.getSource("_measurements").setData({
        type: "FeatureCollection",
        features: labelFeatures
      });
    });

    return function cleanup() {
      map.remove();
    };
  }, []);

  // Hooks for handling prop changes.
  // MapStyle updates.
  useEffect(() => {
    if (!map) {
      return;
    }
    map.setStyle(mapStyle);
  }, [map, mapStyle]);

  // Draw mode update.
  // When Draw mode is on, it is assumed that the Draw controls have exclusive
  // control over the "editableGeojson" source (in MapStyle). If this source
  // is changed through other means, there is no guarantee that the change
  // will appear on the map or persist.
  useEffect(() => {
    if (!map) {
      return;
    }
    if (drawMode) {
      // Draw controls should only be added once.
      if (drawControlRef.current !== null) {
        return;
      }
      //console.log("Adding Draw controls.");
      drawControlRef.current = new MapboxDraw();
      map.addControl(drawControlRef.current);
      // Import the user's GeoJSON into Draw controls.
      const ms = map.getStyle();
      drawControlRef.current.add(ms.sources["editableGeojson"].data);
      // Remove the user's GeoJSON (as it is now visible in sources/layers
      // controlled by the Draw controls and will be restored when Draw controls
      // are disabled).
      ms.sources["editableGeojson"].data = defaultGeojson;
      map.setStyle(ms);
      map.resize();
      return;
    }
    if (drawControlRef.current === null) {
      // The Draw controls do not need to be removed, because they don't exist.
      // This will happen the first time this hook is run (after the map is initialized).
      return;
    }
    //console.log("Removing Draw controls.");
    // Retrieve the result of the drawing session.
    const drawnGeojson = drawControlRef.current.getAll();
    // To prevent Geojsonhint from complaining that "polygons and multipolygons
    // should follow the right-hand rule", use rewind to fix any backwards
    // windings. Without this, geojsonhint will reject polygons drawn clockwise.
    const rewoundGeojson = rewind(drawnGeojson);
    // There is a bug with mapbox-gl-draw that causes it to return invalid
    // GeoJSON when a tool is clicked but not used.
    // https://github.com/mapbox/mapbox-gl-draw/issues/774
    // Workaround:
    //  Check if the last feature is valid. If it is valid, everything is fine.
    //  If the last feature is invalid, then assume this bug occured and repair
    //  the FeatureCollection by removing the last feature.
    if (rewoundGeojson.features.length > 0) {
      const lastFeature =
        rewoundGeojson.features[rewoundGeojson.features.length - 1];
      const lintErrors = geojsonhint.hint(lastFeature);
      if (lintErrors.length > 0) {
        rewoundGeojson.features.pop();
      }
    }
    // Remove Draw controls.
    map.removeControl(drawControlRef.current);
    drawControlRef.current = null;
    // Push the result of the drawing session into the "editableGeojson" source.
    dispatch({
      type: "setGeojson",
      newValue: JSON.stringify(rewoundGeojson)
    });
    map.resize();
  }, [map, drawMode, dispatch]);

  return <div style={{ width: width, height: height }} ref={mapContainerRef} />;
}

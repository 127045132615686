import cogoToast from "cogo-toast";
import LZString from "lz-string";

import copyToClipboard from "./copyToClipboard";

/**
 * Implements the "Export to URL" feature.
 *
 * This takes a given GeoJSON object, compresses it, and stuffs it into the
 * browser's URL bar and the user's clipboard.
 *
 * Within a URL, GeoJSON is stored in the querystring:
 *    <domain.com>/?geojson=N4IgLgngDgpiBcIBiMCGYCuAnGBhA9gDaEwD...
 *
 * @param {object} geojson
 */
export default function exportGeojsonToUrl(geojson) {
  // Stringify and compress GeoJSON.
  const geojsonString = JSON.stringify(geojson);
  const compressed = LZString.compressToEncodedURIComponent(geojsonString);

  // Build a new URL out of the current one and place it in the URL bar.
  const currentUrl = new URL(window.location.href);
  const newUrl = `${currentUrl.origin}/?geojson=${compressed}/${currentUrl.hash}`;
  // URLs are limited to about 2000 characters. Let's keep it at 1900 (just
  // to be safe). https://stackoverflow.com/a/417184/6591491
  if (newUrl.length > 1900) {
    const errorMsg = `Sorry, your GeoJSON could not be exported as it is too large.\nURLs should be shorter than ~2000 characters, yours would be ${newUrl.length}.`;
    cogoToast.error(errorMsg);
    return;
  }
  window.history.replaceState({}, "Export GeoJSON to URL bar.", newUrl);

  // Copy to the user's clipboard.
  const copyAction = copyToClipboard(newUrl);
  if (copyAction.ok) {
    cogoToast.success("GeoJSON copied to clipboard!");
  } else {
    cogoToast.error(
      `Failed to copy GeoJSON to clipboard. Error: ${copyAction.error.message}.`
    );
    console.error(copyAction.error);
  }
}

//import React from "react";
import cogoToast from "cogo-toast";

import { validateGeojson } from "./geojson";

function handleDragOver(event) {
  event.preventDefault();
  event.dataTransfer.dropEffect = "copy";
}

function handleDragEnter() {
  document.body.style.opacity = 0.5;
}
function handleDragLeave() {
  document.body.style.opacity = 1;
}

function handleDrop(dispatch, event) {
  event.preventDefault();
  var reader = new FileReader();
  reader.addEventListener(
    "load",
    function(event) {
      const geojsonString = event.target.result;
      const validation = validateGeojson(geojsonString);
      if (!validation.ok) {
        cogoToast.error(
          "File is not valid GeoJSON. See console for more details."
        );
        console.error(
          "Geojson is invalid. Validation errors and the validated GeoJSON will be printed below."
        );
        console.log(validation.error);
        console.log(validation.geojson);
        return;
      }
      dispatch({ type: "setGeojson", newValue: geojsonString });
    },
    false
  );
  reader.readAsText(event.dataTransfer.files[0]);
  document.body.style.opacity = 1;
}

export default function DragAndDrop(appDispatch) {
  const appliedHandleDrop = e => handleDrop(appDispatch, e);

  function enableDragAndDrop() {
    document.addEventListener("dragover", handleDragOver, false);
    document.addEventListener("dragenter", handleDragEnter, false);
    document.addEventListener("dragleave", handleDragLeave, false);
    document.addEventListener("drop", appliedHandleDrop, false);
  }

  function disableDragAndDrop() {
    document.removeEventListener("dragover", handleDragOver, false);
    document.removeEventListener("dragenter", handleDragEnter, false);
    document.removeEventListener("dragleave", handleDragLeave, false);
    document.removeEventListener("drop", appliedHandleDrop, false);
  }

  return [enableDragAndDrop, disableDragAndDrop];
}

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as turf from "@turf/turf";

import { helpText, welcomeMessage } from "./messages";

Terminal.propTypes = {
  geojson: PropTypes.object.isRequired,
  drawMode: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
};

// window.gs when methods are unavailable.
const placeholder =
  "Unavailable when draw mode is on, or before initialization.";
const inactiveGs = {
  help: () => placeholder,
  get: () => placeholder,
  set: () => placeholder,
  getMap: () => placeholder,
  turf: placeholder
};

export default function Terminal(props) {
  const { geojson, drawMode, dispatch } = props;

  useEffect(() => {
    console.log(welcomeMessage);
  }, []);

  const dispatchRef = useRef(dispatch);
  useEffect(() => {
    if (drawMode) {
      window.gs = inactiveGs;
      return;
    }
    window.gs = {
      help: () => console.log(helpText),
      get: () => geojson,
      set: newValue =>
        dispatchRef.current({
          type: "setGeojson",
          newValue: JSON.stringify(newValue)
        }),
      getMap: () => window._gsMap,
      turf: turf
    };
  }, [geojson, drawMode]);

  // Update getGeojson method.
  useEffect(() => {
    window.gs.get = () => props.geojson;
  }, [props.geojson]);

  return <></>;
}

export const helpText = `Docs

gs.get() -- Returns a deepcopy of the current GeoJSON shown on the map and in
            the editor.

gs.set(featureCollection) -- Replaces the current GeoJSON FeatureCollection.

gs.getMap() -- Returns the internal mapbox-gl-js Map object (if it is ready)

gs.turf -- The complete Turf.js library, ready to use.

---

An Example...

const randomPoints = gs.turf.randomPoint(25);
gs.set(randomPoints);
// The map should be populated with a bunch of random Point Features.
`;

export const welcomeMessage = `
Hello fellow Hacker!

You will find useful methods and properties under 'window.gs'. Try 'gs.help()'.

Note: API methods are disabled when draw mode is active!
`;

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import cogoToast from "cogo-toast";
import shpwrite from "shp-write";
import tokml from "geojson-to-kml";

import "./DropdownMenu.css";
import exportGeojsonToUrl from "../../utils/exportGeojsonToUrl";
import { validateGeojson } from "../../utils/geojson";

DropdownMenu.propTypes = {
  appState: PropTypes.object.isRequired,
  appDispatch: PropTypes.func.isRequired
};

export default function DropdownMenu(props) {
  const { appState, appDispatch } = props;
  const fileInputRef = useRef(null);

  // File uploads are handled by a hidden <input>. When the user clicks on
  // "Upload file", the hidden <input> is clicked and a native file picker appears.
  // When a file is selected, onFileUpload() will be called.
  const onFileUpload = e => {
    const { files } = e.target;
    const file = files[0];

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const readFile = reader.result;
      const validation = validateGeojson(readFile);
      if (!validation.ok) {
        cogoToast.error(
          "File is not valid GeoJSON. See console for more details."
        );
        console.error(
          "Geojson is invalid. Validation errors and the validated GeoJSON will be printed below."
        );
        console.log(validation.error);
        console.log(validation.geojson);
        return;
      }
      appDispatch({ type: "setGeojson", newValue: readFile });
    });

    reader.readAsText(file);
  };
  const fileUpload = () => {
    fileInputRef.current.click();
  };

  // Extract and/or derive some state.
  const currentGeojson = appState.mapStyle.sources["editableGeojson"].data;
  // Is the satellite layer active?
  const satelliteLayer = appState.mapStyle.layers.reduce((acc, currLayer) => {
    if (currLayer.id === "satellite") {
      return currLayer.layout.visibility === "visible";
    }
    return acc;
  }, false);

  // Implements the File, New button.
  // Clears the URL bar and the user's GeoJSON.
  const fileNew = () => {
    // Restore the URL bar to an empty state (for it may have encoded
    // geojson in it).
    const currentUrl = new URL(window.location.href);
    window.history.replaceState({}, "Clear URL bar", currentUrl.origin);
    // Return to initial state.
    appDispatch({ type: "clear" });
  };

  // Implements the File, Link button.
  // Generates a shareable URL to the current map and GeoJSON (if possible).
  // The URL will replace the current URL (in the URL bar) and be copied to the
  // user's clipboard.
  const fileLink = () => {
    exportGeojsonToUrl(currentGeojson);
    // Get the current location from window.location...ugh this
    // does not seem like a good solution...
    const currentUrl = new URL(window.location.href);
    const localStorage = window.localStorage;
    localStorage.setItem("lastKnownHash", currentUrl.hash);
  };

  // Implements the Save, GeoJSON button.
  // Saves the user's GeoJSON to their computer.
  const saveGeojson = () => {
    const geojsonBlob = new Blob([JSON.stringify(currentGeojson)], {
      type: "text/plain;charset=utf-8"
    });
    saveAs(geojsonBlob, "map.geojson");
  };
  const saveShapefile = () => {
    shpwrite.download(currentGeojson);
  };
  const saveKml = () => {
    const kmlBlob = new Blob([tokml(currentGeojson)], {
      type: "text/plain;charset=utf-8"
    });
    saveAs(kmlBlob, "map.kml");
  };

  // Implements the Satellite button.
  const toggleSatellite = () => appDispatch({ type: "toggleSatelliteLayer" });

  // Implements the Draw button.
  const toggleDrawMode = () => appDispatch({ type: "toggleDrawMode" });

  // The Draw controls do not play well with other controls that manipulate state.
  // To avoid weird interactions, most buttons are disabled when draw mode is on.
  if (appState.drawMode) {
    return (
      <nav role="navigation">
        <ul>
          <li onClick={toggleSatellite}>
            <button className="link-button">
              {satelliteLayer ? "Streets" : "Satellite"}
            </button>
          </li>
          <li onClick={toggleDrawMode}>
            <button className="link-button">
              {appState.drawMode ? "Stop Drawing" : "Draw"}
            </button>
          </li>
        </ul>
        <input
          type="file"
          className="file-upload"
          ref={fileInputRef}
          onChange={onFileUpload}
        />
      </nav>
    );
  }

  return (
    <nav role="navigation">
      <ul>
        <li>
          <button className="link-button">File</button>
          <ul className="dropdown" aria-label="submenu">
            <li onClick={fileNew}>
              <button className="link-button">New</button>
            </li>
            <li onClick={fileUpload}>
              <button className="link-button">Upload GeoJSON</button>
            </li>
            <li onClick={fileLink}>
              <button className="link-button">Link</button>
            </li>
          </ul>
        </li>
        <li>
          <button className="link-button">Save</button>
          <ul className="dropdown" aria-label="submenu">
            <li onClick={saveGeojson}>
              <button className="link-button">GeoJSON</button>
            </li>
            <li onClick={saveShapefile}>
              <button className="link-button">Shapefile</button>
            </li>
            <li onClick={saveKml}>
              <button className="link-button">KML</button>
            </li>
          </ul>
        </li>
        <li onClick={toggleSatellite}>
          <button className="link-button">
            {satelliteLayer ? "Streets" : "Satellite"}
          </button>
        </li>
        <li onClick={toggleDrawMode}>
          <button className="link-button">
            {appState.drawMode ? "Stop Drawing" : "Draw"}
          </button>
        </li>
      </ul>
      <input
        type="file"
        className="file-upload"
        ref={fileInputRef}
        onChange={onFileUpload}
      />
    </nav>
  );
}

import { defaultGeojson } from "../utils/geojson";
import { streetsLayers } from "./layers/streets";

export const defaultMapStyle = {
  version: 8,
  name: "GeoJSONStudio",
  center: [5, 34],
  zoom: 1.5,
  sprite:
    "mapbox://sprites/bryik/ck5actjs70yo81co6aya38w26/2sjxyrpjalvrdfs6mykcpjmtj",
  glyphs: "mapbox://fonts/bryik/{fontstack}/{range}.pbf",
  sources: {
    composite: {
      url: "mapbox://mapbox.mapbox-streets-v8,mapbox.mapbox-terrain-v2",
      type: "vector"
    },
    satellite: {
      url: "mapbox://mapbox.satellite",
      type: "raster",
      tileSize: 256
    },
    _measurements: {
      type: "geojson",
      data: defaultGeojson
    },
    editableGeojson: {
      type: "geojson",
      data: defaultGeojson
    }
  },
  layers: [
    ...streetsLayers,
    {
      id: "editableGeojsonPoints",
      source: "editableGeojson",
      type: "circle",
      paint: {
        "circle-radius": 6,
        "circle-color": "#B42222"
      },
      filter: ["==", "$type", "Point"]
    },
    {
      id: "editableGeojsonLineStrings",
      type: "line",
      source: "editableGeojson",
      paint: {
        "line-width": 1
      },
      filter: ["==", "$type", "LineString"]
    },
    {
      id: "editableGeojsonPolygons",
      type: "fill",
      source: "editableGeojson",
      paint: {
        "fill-color": "#888888",
        "fill-opacity": 0.4
      },
      filter: ["==", "$type", "Polygon"]
    },
    {
      id: "_measurements",
      source: "_measurements",
      type: "symbol",
      paint: {
        "text-color": "hsl(234, 100%, 32%)",
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-width": 2
      },
      layout: {
        "text-field": "{label}",
        "text-size": 16
      }
    }
  ]
};

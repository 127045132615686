import React, { useState } from "react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";

import { validateGeojson } from "../../utils/geojson";
import { charIndexToRowAndColumn } from "./helpers";

Editor.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func
};
Editor.defaultProps = {
  width: "100vw",
  height: "100vh",
  value: "",
  defaultValue: ""
};

/**
 * Text editor UI for GeoJSON.
 *
 * Edits are validated before new GeoJSON is dispatched to the store. If an
 * edit is invalid JSON or fails to pass geojsonhint, the editor will annotate
 * the text with the relevant error. An edit producing valid JSON/GeoJSON clears
 * the error state and dispatches the change to the store.
 *
 * If the user's GeoJSON changes while the editor is in an error state (e.g.
 * a new Point is added via the draw tool), the editor's (invalid) GeoJSON will
 * be replaced.
 */
export default function Editor(props) {
  const [annotations, setAnnotations] = useState([]);
  // Holds temporary text that is not valid JSON/GeoJSON.
  const [invalidValue, setInvalidValue] = useState(null);
  const textToDisplay = invalidValue === null ? props.value : invalidValue;
  return (
    <AceEditor
      height={props.height}
      width={props.width}
      value={textToDisplay}
      defaultValue={props.defaultValue}
      onChange={newValue => {
        // Validate before calling props.onChange() to reduce pointless calls.
        const validation = validateGeojson(newValue);

        // Handle case where the user has entered invalid JSON/GeoJSON.
        if (!validation.ok) {
          // Immediately set text so the editor feels responsive.
          setInvalidValue(newValue);

          // Compute new error annotations.
          let newAnnotations;
          const validationErrors = validation.error;

          // Handle JSON parse errors
          if (validationErrors[0].name === "SyntaxError") {
            // Extract position of error from JSON parse error message.
            const errorMsg = validationErrors[0].message;
            const errorPositionMatch = errorMsg.match(/position (.*)/);
            const errorPosition = Number(errorPositionMatch[1]);
            // errorPosition is a character index, convert it to row and column
            // indices for Ace Editor.
            const { row, column } = charIndexToRowAndColumn(
              newValue,
              errorPosition
            );
            validationErrors[0] = {
              message: validationErrors[0].message,
              line: row
            };
            newAnnotations = [
              {
                row,
                column,
                text: errorMsg,
                type: "error"
              }
            ];
          } else {
            // Handle geojsonhint errors.
            // Transform geojsonhints into annotation objects Ace Editor understands.
            newAnnotations = validationErrors.map(v => {
              return { row: v.line, column: 0, text: v.message, type: "error" };
            });
          }
          setAnnotations(newAnnotations);
          return;
        }

        // Check newValue is actually new GeoJSON and not just a whitespace change.
        // Without this check (and early return), only edits that change the
        // actual value of the parsed JSON object can be made. For example,
        // inserting a newline at the end of the text would be impossible.
        const formattedNewValue = JSON.stringify(JSON.parse(newValue), null, 2);
        if (formattedNewValue === props.value) {
          return;
        }

        // Reset error state. Manual editing usually causes an error state
        // and a reset shouldn't hurt even if edits were all valid.
        setInvalidValue(null);
        setAnnotations([]);
        props.onChange(newValue);
      }}
      mode="json"
      theme="github"
      name="geojsonEditor"
      fontSize={14}
      tabSize={2}
      setOptions={{ useWorker: false }}
      style={props.style}
      annotations={annotations}
    />
  );
}

import geojsonhint from "@mapbox/geojsonhint";

export const defaultGeojson = {
  type: "FeatureCollection",
  features: []
};

/**
 * Parses and validates GeoJSON.
 * @param {string} geojson
 * @returns {ok: bool, error: list[Error | object],  geojson: object}
 */
export function validateGeojson(geojson) {
  // First try to parse.
  let parsedGeojson;
  try {
    parsedGeojson = JSON.parse(geojson);
  } catch (error) {
    return { ok: false, error: [error] };
  }

  // Next try to pass lint.
  const lintErrors = geojsonhint.hint(geojson);
  if (lintErrors.length > 0) {
    return { ok: false, error: lintErrors, geojson: parsedGeojson };
  }

  return { ok: true, geojson: parsedGeojson };
}

import React, { useReducer, useEffect, useRef } from "react";
import LZString from "lz-string";

import { initialState, reducer } from "./reducer";
import { defaultGeojson } from "./utils/geojson";
import DragAndDrop from "./utils/DragAndDrop";
import Map from "./components/Map";
import Editor from "./components/Editor";
import Terminal from "./components/Terminal";
import DropdownMenu from "./components/DropdownMenu";

function App() {
  // Reducer and state change methods.
  const [state, dispatch] = useReducer(reducer, initialState);

  // If draw mode is off, display the current GeoJSON in a live text editor.
  let editor = null;
  let mapWidth = "100vw";
  // Nicely format GeoJSON for the text editor.
  const currentGeojson = state.mapStyle.sources["editableGeojson"].data;
  const formattedGeojson = JSON.stringify(currentGeojson, null, 2);
  if (!state.drawMode) {
    editor = (
      <Editor
        width="35vw"
        value={formattedGeojson}
        defaultValue={JSON.stringify(defaultGeojson, null, 2)}
        onChange={newValue => dispatch({ type: "setGeojson", newValue })}
      />
    );
    mapWidth = "65vw";
  }

  // On initialization, import GeoJSON from location.href (if possible).
  useEffect(() => {
    const url = new URL(window.location.href);
    // Handle GeoJSON (in querystring)
    const toImport = url.searchParams.get("geojson");
    if (toImport !== null) {
      const decompressed = LZString.decompressFromEncodedURIComponent(toImport);
      dispatch({ type: "setGeojson", newValue: decompressed });
    }
    // Restore hash from the last time the share button was used.
    const localStorage = window.localStorage;
    const lastKnownHash = localStorage.getItem("lastKnownHash");
    if (lastKnownHash !== null) {
      window.location.hash = lastKnownHash;
    }
  }, []);

  // Enable drag and drop file uploading when draw mode is off.
  const dragAndDropRef = useRef(DragAndDrop(dispatch));
  useEffect(() => {
    const [enableDragAndDrop, disableDragAndDrop] = dragAndDropRef.current;
    if (state.drawMode) {
      disableDragAndDrop();
    } else {
      enableDragAndDrop();
    }
  }, [state.drawMode]);

  return (
    <div style={{ display: "flex" }}>
      <Map
        width={mapWidth}
        drawMode={state.drawMode}
        mapStyle={state.mapStyle}
        dispatch={dispatch}
      />
      {editor}
      <div style={{ position: "absolute" }}>
        <DropdownMenu appState={state} appDispatch={dispatch} />
      </div>
      <Terminal
        geojson={currentGeojson}
        dispatch={dispatch}
        drawMode={state.drawMode}
      />
    </div>
  );
}

export default App;

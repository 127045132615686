import produce from "immer";

import { validateGeojson } from "./utils/geojson";
import { defaultMapStyle } from "./mapStyle/defaultMapStyle";

export const initialState = {
  // Whether the Map's draw mode is on or off.
  drawMode: false,
  mapStyle: defaultMapStyle
};

export function reducer(state, action) {
  switch (action.type) {
    case "clear":
      return initialState;
    case "setMapStyle":
      return { ...state, mapStyle: action.newMapStyle };
    case "toggleDrawMode":
      return { ...state, drawMode: !state.drawMode };
    case "setGeojson":
      // action.newValue should be new GeoJSON in string form.
      const validation = validateGeojson(action.newValue);
      if (!validation.ok) {
        // Ignore "Polygons and MultiPolygons should follow the right-hand rule"
        // error. IMO, this rule is too annoying to follow. It was introduced in
        // the second version of the GeoJSON spec and tools like Turf.js can still
        // output polygons that break it. https://github.com/mapbox/geojsonhint/pull/76
        if (
          validation.error[0] !==
          "Polygons and MultiPolygons should follow the right-hand rule"
        ) {
          console.error(
            "Geojson is invalid. Validation errors and the validated GeoJSON will be printed below."
          );
          console.log(validation.error);
          console.log(validation.geojson);
          return state;
        }
      }
      // Editor change resulted in new, valid GeoJSON so update the
      // editableGeojson source.
      return produce(state, draftState => {
        const editableGeojsonSource =
          draftState.mapStyle.sources["editableGeojson"];
        editableGeojsonSource.data = validation.geojson;
      });
    case "toggleSatelliteLayer":
      return produce(state, draftState => {
        draftState.mapStyle.layers.forEach(layer => {
          if (layer.id === "satellite") {
            const currentVisibility = layer.layout.visibility;
            const newVisibility =
              currentVisibility === "none" ? "visible" : "none";
            layer.layout.visibility = newVisibility;
          }
        });
      });
    default:
      console.warn(`Unknown action type: ${action.type}`);
      return state;
  }
}
